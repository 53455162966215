<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="500"
    :title="$t('customer.orders.closeOrderDialogTitle', [order.orderNumber])"
  >
    <div class="d-flex align-center pa-6" style="column-gap: 10px">
      <cz-icon :src="mdiAlertCircleOutline" color="negative" />
      <div class="text-subtitle-2 font-weight-semibold negative--text">
        {{ $t('customer.orders.closeOrderNote', [order.orderNumber]) }}
      </div>
    </div>
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form :disabled="loading" class="px-6">
        <cz-form-field :label="$t('customer.orders.closingReason')" required>
          <validation-provider
            rules="required"
            :name="$t('customer.orders.closingReason')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="reason"
              multi-line
              :error-messages="errors"
              dense
            />
          </validation-provider>
        </cz-form-field>

        <cz-button
          :title="$t('customer.orders.closeOrderButtonTitle')"
          color="primary"
          :disabled="invalid"
          :icon-src="mdiCancel"
          :loading="loading"
          :dark="!invalid"
          @click="$emit('done', reason)"
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzFormField, CzInput, CzButton, CzIcon } from '@/components';
import { mdiCancel, mdiAlertCircleOutline } from '@mdi/js';
export default {
  name: 'CloseOrderDialog',
  components: {
    CzFormField,
    CzDialog,
    CzInput,
    CzButton,
    CzIcon
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiCancel,
      mdiAlertCircleOutline,
      reason: ''
    };
  }
};
</script>

<style></style>
